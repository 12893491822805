import React from 'react';
import { Route, Routes } from "react-router-dom";

// Home
import Home from '../pages/home/home';
import About from '../pages/about/about';
import Web from '../pages/services/Web';
import Application from '../pages/services/Application';
import Automation from '../pages/services/Automation';
import Data from '../pages/services/Data';
import Digital from '../pages/services/Digital';

import Contact from '../pages/contact/contact';


function RoutesData(props) {
    return (
        <Routes>
            {/*Home */}
            <Route exact path="/" element={<Home/>} />

            {/* About */}
            <Route exact path="/about" element={<About/>} />

            {/* Services */}
            <Route exact path="/web" element={<Web/>} />
            <Route exact path="/app" element={<Application/>} />
            <Route exact path="/automation" element={<Automation/>} />
            <Route exact path="/data-processing" element={<Data/>} />
            <Route exact path="/digital-marketing" element={<Digital/>} />


            {/* Contact */}
            <Route exact path="/contact" element={<Contact/>} />


        </Routes>

    );

}

export default RoutesData;

import React, { Component } from "react";


import BannerTitle from "../../components/BannerTitle";

import data1 from '../../assets/images/servicesdata/data1.svg';
import data2 from '../../assets/images/servicesdata/data2.svg';
import data3 from '../../assets/images/servicesdata/data3.svg';
import data4 from '../../assets/images/servicesdata/data4.svg';
import data5 from '../../assets/images/servicesdata/data5.svg';
import data6 from '../../assets/images/servicesdata/data6.svg';
import data7 from '../../assets/images/servicesdata/data7.svg';


class Data extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <BannerTitle title="Data Processing" />

                <section className="section gmb web-design">
                    <div className="gmb-item-main">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                    <div className="image">
                                        <img src={data1} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="content-item">
                                        <h3 className="light-black-txt fw600 mb-4">What is Data Processing ?</h3>
                                        <div className="desc text-justify">
                                            <p>
                                                Data processing occurs when data is collected and translated into usable information. Usually performed by a data scientist or team of data scientists, it is important for data processing to be done correctly as not to negatively affect the end product, or data output.
                                            </p>
                                            <ul>
                                                <li>Helping develop reports and analyses.</li>
                                                <li>Processing confidential data and information according to guidelines.</li>
                                                <li>Data collection, troubleshooting data issues, cleaning, and interpreting data sets in order to answer a question or solve a problem.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section  home-about gmb-next-level web-design-together service-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Services</h3>
                                </div>
                            </div>
                        </div>
                        <div class="what-we-offer-item-main">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={data2} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Medical & Health Care Analyst</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={data3} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Market Research Analyst</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={data4} class="img-fluid" alt="Service Image/" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Business Analyst</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={data5} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Business Intelligence Analyst</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={data6} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Operations Research Analyst</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={data7} class="img-fluid" alt="Service Image/" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Intelligence Analyst</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Data;
import React, { Component } from "react";

import { Carousel, CarouselItem } from 'react-bootstrap';

import client from '../assets/images/client.svg';

class Testimonials extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <>
                <section class="section home-testimonials grey-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h2 class="black-txt fw700 mb-3">Testimonial's</h2>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial-item-main">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <Carousel className="owl-carousel owl-testi owl-theme" controls={false} indicators={true} touch={true} slide={true} interval={3000}>
                                                <Carousel.Item>
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                            <div class="item h-100">
                                                                <div class="image mt-5">
                                                                    <img src={client} class="img-fluid" alt="Testimonial Image" />
                                                                </div>
                                                                <ul class="ratings mt-4">
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                </ul>
                                                                <div class="comment py-4">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                </div>
                                                                <h5 class="black-txt fw800 mb-2">Raj Tolani</h5>
                                                                <h5 class="fw500">India</h5>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                            <div class="item h-100">
                                                                <div class="image mt-5">
                                                                    <img src={client} class="img-fluid" alt="Testimonial Image" />
                                                                </div>
                                                                <ul class="ratings mt-4">
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                </ul>
                                                                <div class="comment py-4">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                </div>
                                                                <h5 class="black-txt fw800 mb-2">Christopher Doe</h5>
                                                                <h5 class="fw500">USA</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Carousel.Item>

                                                <Carousel.Item>
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                            <div class="item h-100">
                                                                <div class="image mt-5">
                                                                    <img src={client} class="img-fluid" alt="Testimonial Image" />
                                                                </div>
                                                                <ul class="ratings mt-4">
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                </ul>
                                                                <div class="comment py-4">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                </div>
                                                                <h5 class="black-txt fw800 mb-2">Harry Potter</h5>
                                                                <h5 class="fw500">UK</h5>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                            <div class="item h-100">
                                                                <div class="image mt-5">
                                                                    <img src={client} class="img-fluid" alt="Testimonial Image" />
                                                                </div>
                                                                <ul class="ratings mt-4">
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                                                                </ul>
                                                                <div class="comment py-4">
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                </div>
                                                                <h5 class="black-txt fw800 mb-2">Harmayini Grenger</h5>
                                                                <h5 class="fw500">UK</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Carousel.Item>
                                                
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Testimonials;
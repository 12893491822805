import React, { Component } from "react";

import { IMG_BASE_URL, OTHER_IMG_BASE_URL, PDF_BASE_URL } from "../../config/settings";

import Testimonials from "../../components/Testimonials";

import Background from '../../assets/images/slider.jpg';
import bg from '../../assets/images/chatbot.svg';
import about from '../../assets/images/about.svg';
import client from '../../assets/images/client.svg';
import service1 from '../../assets/images/services/Web.svg';
import service2 from '../../assets/images/services/Robotic.svg';
import service3 from '../../assets/images/services/Data.svg';
import service4 from '../../assets/images/services/Api.svg';
import service5 from '../../assets/images/services/Digital_Marketing.svg';
import service6 from '../../assets/images/services/App.svg';


import { Fade } from "react-awesome-reveal";
import { NavLink } from 'react-router-dom';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <>
                <div class="head-bg">
                    <div class="home-banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="banner-content-item">
                                        <div className="banner-content-item-inner">
                                            <div className="semi-txt light-black-txt">Looking for</div>
                                            <h2 className="light-black-txt fw700">Automation ?</h2>
                                            <div className="desc pt-3 pb-4">
                                                We help individuals and businesses to get automate digital tasks fast by designing modern solutions that help you stand out from your competition without being left behind.
                                            </div>
                                            <NavLink to="/automation" className="border-btn banner-contact">Learn More</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <img className="img-fluid" src={bg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="section home-about">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="image">
                                        <img src={about} class="img-fluid" alt="About" />
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h5 class="light-purple-txt mb-3">ABOUT</h5>
                                            <h2 class="light-black-txt fw700">Who We Are</h2>
                                            <div class="desc py-4 text-justify">
                                                We are a software development company that provides cutting edge engineering solutions,combining deep industry expertise and technical skills to connect you to the right IT solutions.
                                            </div>
                                            <NavLink to="/about" className="border-btn banner-contact">Read More</NavLink>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h5 class="light-purple-txt mb-3">CLIENT SUCCESS</h5>
                                            <h2 class="light-black-txt fw700">Client Satisfaction is key to Success</h2>
                                            <div class="desc py-4 text-justify">
                                                We find the best solutions for our clients because we value their reputation. We have many satisfied clients that have chosen us.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={client} class="img-fluid" alt="Client" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section service-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h6 class="lazurite-blue-txt fw700 mb-4">Services</h6>
                                    <h2 class="section-title work blue-txt fw700 text-uppercase">What We Offer</h2>
                                </div>
                            </div>
                        </div>
                        <div class="what-we-offer-item-main">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <NavLink to="/web" className="light-black-txt">
                                        <div class="h-100 what-we-offer-item text-center">
                                            <div class="icn">
                                                <img src={service1} class="img-fluid" alt="Service Image" />
                                            </div>
                                            <h4 class="light-purple-txt fw600 py-3">Website Development</h4>
                                            <div class="desc">
                                                Our web development services are affordable and reliable. Additionally, we have a flexible working style that suits your requirement.
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <NavLink to="/automation" className="light-black-txt">
                                        <div class="h-100 what-we-offer-item text-center">
                                            <div class="icn">
                                                <img src={service2} class="img-fluid" alt="Service Image" />
                                            </div>
                                            <h4 class="light-purple-txt fw600 py-3">Robotic Process Automation & ChatBot</h4>
                                            <div class="desc">
                                                Our team is very well experienced in RPA and creating ChatBots for popular social media plateform.
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <NavLink to="/data-processing" className="light-black-txt">
                                        <div class="h-100 what-we-offer-item text-center">
                                            <div class="icn">
                                                <img src={service3} class="img-fluid" alt="Service Image/" />
                                            </div>
                                            <h4 class="light-purple-txt fw600 py-3">Data Processing</h4>
                                            <div class="desc">
                                                KM Inc provide fast and reliable data processing across wolrd which are the best in the industry.
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <NavLink to="/web" className="light-black-txt">
                                        <div class="h-100 what-we-offer-item text-center">
                                            <div class="icn">
                                                <img src={service4} class="img-fluid" alt="Service Image" />
                                            </div>
                                            <h4 class="light-purple-txt fw600 py-3">REST API</h4>
                                            <div class="desc">
                                                REST API is a way of accessing web services in a simple and flexible way and we have mastered ourselves in that.
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <NavLink to="/digital-marketing" className="light-black-txt">
                                        <div class="h-100 what-we-offer-item text-center">
                                            <div class="icn">
                                                <img src={service5} class="img-fluid" alt="Service Image" />
                                            </div>
                                            <h4 class="light-purple-txt fw600 py-3">Digital Marketing using Automation</h4>
                                            <div class="desc">
                                                Our Digital marketing experts have good hands-on all social media plateform.
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <NavLink to="/app" className="light-black-txt">
                                        <div class="h-100 what-we-offer-item text-center">
                                            <div class="icn">
                                                <img src={service6} class="img-fluid" alt="Service Image/" />
                                            </div>
                                            <h4 class="light-purple-txt fw600 py-3">App Development</h4>
                                            <div class="desc">
                                                Our mobile apps deliver easy and superior experiences across various devices.
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section home schedule" style={{ backgroundImage: `url(${Background})` }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="content-item text-center">
                                    <h3 class="white-txt fw700 text-uppercase">Schedule a Meeting </h3>
                                    <div class="desc py-4">
                                        With Business Focused Approach let Us Be The Trusted Partner of Your Business.
                                    </div>
                                    <a href="https://wa.me/message/3IAU7PM6NHR5C1" target={"_blank"} className="gradient-btn" title="schedule">Connect With Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Testimonials /> */}

            </>
        );
    }
}

export default Home;
import React, { Component } from "react";

//Routes
import Routes from "./Routes";

//General App Layout
import Header from './Header';
import Footer from "./Footer";

//App Laayout
class AppLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (<div>
            <Header />
            <Routes />
            <Footer />
        </div>);
    }
}

export default AppLayout;
import React, { Component } from "react";


import BannerTitle from "../../components/BannerTitle";

import digital1 from '../../assets/images/servicesdata/digital1.svg';
import digital2 from '../../assets/images/servicesdata/rpa5.svg';
import digital3 from '../../assets/images/servicesdata/digital3.svg';
import digital4 from '../../assets/images/servicesdata/digital4.svg';
import digital5 from '../../assets/images/servicesdata/digital5.svg';


import { Fade } from "react-awesome-reveal";

class Digital extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <BannerTitle title="Digital Marketing" />

                <section className="section gmb web-design">
                    <div className="gmb-item-main">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                    <div className="image">
                                        <img src={digital1} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="content-item">
                                        <h3 className="light-black-txt fw600 mb-4">What is Digital Marketing ?</h3>
                                        <div className="desc text-justify">
                                            <p>
                                                Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel.
                                            </p>
                                            <p>
                                                Digital marketing involves marketing to consumers through any number of digital channels.This form of marketing is commonly executed on websites, mobile devices, and social media platforms.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="section home-about gmb-next-level web-design-together">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Services</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Social Media Marketing</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    Social media marketing is the use of social media platforms and websites to promote a product or service.
                                                </p>
                                                <p>
                                                    Social media marketing has made possible for companies to reach targeted consumers easily, effectively and instantly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={digital2} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="image">
                                        <img src={digital3} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Pay Per Click</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    PPC stands for pay-per-click, a model of internet marketing in which advertisers pay a fee each time one of their ads is clicked. Essentially, it’s a way of buying visits to your site, rather than attempting to “earn” those visits organically.
                                                </p>
                                                <p>
                                                    Search engine advertising is one of the most popular forms of PPC. It allows advertisers to bid for ad placement in a search engine’s sponsored links when someone searches on a keyword that is related to their business offering.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Content Marketing</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    Content marketing is a form of marketing focused on creating, publishing, and distributing content for a targeted audience online.
                                                </p>
                                                <p>
                                                    Content has the potential to connect deeply with your target audience. Our content has the answers that your target audience is looking out for.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={digital4} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="image">
                                        <img src={digital5} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Email Marketing</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    Email marketing is the act of sending a commercial message, typically to a group of people, using email. In its broadest sense, every email sent to a potential or current customer could be considered email marketing.
                                                </p>
                                                <p>
                                                    It can help make your customers aware of your latest items or offers by integrating it into your marketing automation efforts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Digital;
import React, { Component } from "react";


import BannerTitle from "../../components/BannerTitle";

import web1 from '../../assets/images/servicesdata/web1.svg';
import web2 from '../../assets/images/servicesdata/web2.svg';
import web3 from '../../assets/images/servicesdata/web3.svg';
import web4 from '../../assets/images/servicesdata/web4.svg';
import web5 from '../../assets/images/servicesdata/web5.svg';
import web6 from '../../assets/images/servicesdata/web6.svg';

import { Fade } from "react-awesome-reveal";

class Web extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <BannerTitle title="Web Development" />

                <section className="section gmb web-design">
                    <div className="gmb-item-main">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                    <div className="image">
                                        <img src={web1} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="content-item">
                                        <h3 className="light-black-txt fw600 mb-4">What is web development ?</h3>
                                        <div className="desc text-justify">
                                            <p>
                                                Web development refers to the building, creating, and maintaining of websites. It includes aspects such as web design, web publishing, web programming, and database management. It is the creation of an application that works over the internet.
                                            </p>
                                            <p>
                                            In a broader sense, web development encompasses all the actions, updates, and operations required to build, maintain and manage a website to ensure its performance, user experience, and speed are optimal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="section home-about gmb-next-level web-design-together">
                    <div class="container">
                    <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Services</h3>
                                </div>
                            </div>
                        </div>                        
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                    <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Frontend Development</h3>
                                            <div class="desc py-4 text-justify">
                                                Front-end web development, also known as client-side development is the practice of producing HTML, CSS and JavaScript for a website or Web Application so that a user can see and interact with them directly.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={web2} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="image">
                                        <img src={web3} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Backend Development</h3>
                                            <div class="desc py-4 text-justify">
                                                Back-end Development refers to the server-side development. It focuses on databases, scripting, website architecture. It contains behind-the-scene activities that occur when performing any action on a website. It can be an account login or making a purchase from an online store.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                    <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">E-Commerce Development</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    An ecommerce website is a website that allows you to buy or sell products and services online.
                                                </p>
                                                <p>
                                                    Ecommerce websites are perfect for enabling consumers to research, browse, and purchase their favorite products from the comfort of their homes with just a few clicks from their computer, tablet, or mobile device.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={web4} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="image">
                                        <img src={web5} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">CMS Development</h3>
                                            <div class="desc py-4 text-justify">
                                                Content Management System generally refers to an web-based application that enables multiple users with different privilege levels to manage any type of data, content or information of any website application, project on the internet forum.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                    <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Enterprise web App Development</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    Enterprise web development is simply a process to develop a website or an application for enterprise-level business. It revolves around enterprise websites, enterprise web application development, and enterprise software development.
                                                </p>
                                                <p>
                                                    Enterprise web applications are web-based applications that allow you to handle your company’s internal and external needs and operations. A web application gears you towards a digital transformation and helps meet the modern needs of business in this digital world. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={web6} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>                
            </>
        )
    }
}

export default Web;
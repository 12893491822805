import React, { Component } from 'react';
import { BrowserRouter} from 'react-router-dom';

//App Layout
import AppLayout from './layouts/AppLayout';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    return (<div style={{ height: '500px' }}> 
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
    </div >);
  }
}

export default App;
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

//img base url
import { IMG_BASE_URL } from "../config/settings";

import LOGO from '../assets/images/logo.png'

class BannerTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <>
                <div class="internal-banner">
                    <div class="home-banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="banner-content-item">
                                        <div className="banner-content-item-inner">
                                            <h2 className="light-black-txt fw700 text-uppercase">{this.props.title}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BannerTitle;
import React, { Component } from "react";


import BannerTitle from "../../components/BannerTitle";

import app1 from '../../assets/images/servicesdata/app1.svg';
import app2 from '../../assets/images/servicesdata/app2.svg';
import app3 from '../../assets/images/servicesdata/app3.svg';
import app4 from '../../assets/images/servicesdata/app4.svg';

import { Fade } from "react-awesome-reveal";

class Application extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount(){
        window.scrollTo(0, 0)
    }


    render() {
        return (
            <>
                <BannerTitle title="App Development" />

                <section className="section gmb web-design">
                    <div className="gmb-item-main">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                    <div className="image">
                                        <img src={app1} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="content-item">
                                        <h3 className="light-black-txt fw600 mb-4">What is App Development ?</h3>
                                        <div className="desc text-justify">
                                            <p>
                                                Mobile app development is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones.
                                            </p>
                                            <p>
                                                These software applications are designed to run on mobile devices, such as a smartphone or tablet computer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="section home-about gmb-next-level web-design-together">
                    <div class="container">
                    <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Services</h3>
                                </div>
                            </div>
                        </div>                        
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                    <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Android App Development</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    Android software development is the process by which applications are created for devices running the Android operating system.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={app2} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="image">
                                        <img src={app3} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">IOS App Development</h3>
                                            <div class="desc py-4 text-justify">
                                                iOS application development is the process of making mobile applications for Apple hardware, including iPhone, iPad and iPod Touch. The software is written in the Swift programming language or Objective-C and then deployed to the App Store for users to download.
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="left" triggerOnce="true">
                                    <div class="content-item">
                                    <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Cross Plateform App Development</h3>
                                            <div class="desc py-4 text-justify">
                                                <p>
                                                    Cross-platform development is the practice of developing software products or services for multiple platforms or software environments. Engineers and developers use various methods to accommodate different operating systems or environments for one application or product.
                                                </p>
                                                <p>
                                                    Ecommerce websites are perfect for enabling consumers to research, browse, and purchase their favorite products from the comfort of their homes with just a few clicks from their computer, tablet, or mobile device.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <Fade direction="right" triggerOnce="true">
                                    <div class="image">
                                        <img src={app4} class="img-fluid" alt="About Image" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>                
            </>
        )
    }
}

export default Application;
import React, { Component } from "react";

import LOGO from "../assets/images/logo.png"

import { NavLink } from 'react-router-dom';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerBgColor: '#fff',
        }
    }

    listenScrollEvent = e => {
        if (window.scrollY > 100) {
            this.setState({ headerBgColor: '#fff' })
        } else {
            this.setState({ headerBgColor: '#fff' })
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    render() {
        const { headerBgColor } = this.state;
        return (
            <>
                {/* <div className="middle_header mt-lg-4 mt-2 mt-md-4 mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 col-sm-6">
                                <NavLink to="/home"><img src={IMG_BASE_URL + "/logo1.png"} className="logo" alt="" /></NavLink>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 col-sm-6">
                                <div className="d-flex justify-content-end">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <header id="header" style={{ backgroundColor: headerBgColor }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="main-menu">
                                    <nav class="navbar navbar-expand-xl">
                                        <a class="navbar-brand">
                                            <NavLink to="/">
                                                <img src={LOGO} className="img-fluid" width={150} height={150} alt="Logo" />
                                            </NavLink>
                                        </a>
                                        
                                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                            <i class="fa fa-bars" aria-hidden="true"></i>
                                        </button>
                                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul class="navbar-nav mx-auto">
                                                <li class="nav-item">
                                                    <NavLink to="/" className="nav-link">Home</NavLink>
                                                </li>
                                                <li class="nav-item">
                                                    <NavLink to="/about" className="nav-link">About</NavLink>
                                                </li>
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Services
                                                    </a>
                                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <NavLink to="/web" className="dropdown-item">Web Development</NavLink>
                                                        <NavLink to="/automation" className="dropdown-item">RPA & ChatBot</NavLink>
                                                        <NavLink to="/data-processing" className="dropdown-item">Data Processing</NavLink>
                                                        <NavLink to="/digital-marketing" className="dropdown-item">Digital Marketing</NavLink>
                                                        <NavLink to="/app" className="dropdown-item">App Development</NavLink>
                                                    </div>
                                                </li>
                                                {/* <li class="nav-item">
                                                    <a class="nav-link" href="portfolio.html">Portfolio</a>
                                                </li> */}
                                                <li>
                                                    <NavLink to="/contact" className="head-contact">Contact Us</NavLink>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;
import React, { Component } from "react";


import BannerTitle from "../../components/BannerTitle";

import rpa1 from '../../assets/images/servicesdata/rpa1.svg';
import rpa2 from '../../assets/images/servicesdata/rpa2.svg';
import rpa3 from '../../assets/images/servicesdata/rpa3.svg';
import rpa4 from '../../assets/images/servicesdata/rpa4.svg';
import rpa5 from '../../assets/images/servicesdata/rpa5.svg';
import rpa6 from '../../assets/images/servicesdata/rpa6.svg';
import rpa7 from '../../assets/images/servicesdata/rpa7.svg';
import rpa8 from '../../assets/images/servicesdata/rpa8.svg';

import { Fade } from "react-awesome-reveal";

class Automation extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount(){
        window.scrollTo(0, 0)
    }


    render() {
        return (
            <>
                <BannerTitle title="Robotic Process Automation & ChatBot" />

                <section className="section gmb web-design">
                    <div className="gmb-item-main">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                    <div className="image">
                                        <img src={rpa1} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="content-item">
                                        <h3 className="light-black-txt fw600 mb-4">What is RPA ?</h3>
                                        <div className="desc text-justify">
                                            <p>
                                                Robotic process automation (RPA) is a software technology that makes it easy to build, deploy, and manage software robots that emulate humans actions interacting with digital systems and software. Just like people, software robots can do things like understand what’s on a screen, complete the right keystrokes, navigate systems, identify and extract data, and perform a wide range of defined actions. But software robots can do it faster and more consistently than people.
                                            </p>
                                            <p>
                                                Robotic process automation streamlines workflows, which makes organizations more profitable, flexible, and responsive. It also increases employee satisfaction, engagement, and productivity by removing mundane tasks from their workdays.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">                                
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="content-item">
                                        <h3 className="light-black-txt fw600 mb-4">What is ChatBot ?</h3>
                                        <div className="desc text-justify">
                                            <p>
                                                A chatbot is a software or computer program that simulates human conversation or "chatter" through text or voice interactions.
                                            </p>
                                            <p>
                                                A chatbot can be defined as an AI based computer program that simulates human conversations. They are also known as digital assistants that understand human capabilities. Bots interpret the user intent, process their requests, and give prompt relevant answers. 
                                            </p>
                                            <p>
                                                Bots can communicate through voice as well as text and can be deployed across websites, applications, and messaging channels such as Facebook Messenger, Twitter, or Whatsapp.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                                    <div className="image">
                                        <img src={rpa2} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="section  home-about gmb-next-level web-design-together service-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Services</h3>
                                </div>
                            </div>
                        </div>
                        <div class="what-we-offer-item-main">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={rpa3} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">RPA Consulting</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={rpa4} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Customized RPA Solution</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={rpa5} class="img-fluid" alt="Service Image/" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">Automation Bots</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={rpa6} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">RPA Design & Implementation</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={rpa7} class="img-fluid" alt="Service Image" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">RPA Support</h4>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <div class="h-100 what-we-offer-item text-center">
                                        <div class="icn">
                                            <img src={rpa8} class="img-fluid" alt="Service Image/" />
                                        </div>
                                        <h4 class="light-purple-txt fw600 py-3">RPA Proof of Concept</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                                
            </>
        )
    }
}

export default Automation;
import React, { Component } from "react";

import Testimonials from "../../components/Testimonials";

import { Fade } from "react-awesome-reveal";

import BannerTitle from "../../components/BannerTitle";

import about from '../../assets/images/about.svg';
import about2 from '../../assets/images/about2.svg';


class About extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <BannerTitle title="About" />

                <section className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="image">
                                    <img src={about} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="section-top text-left">
                                    <h3 className="light-black-txt fw700 mb-4">Who We Are...</h3>
                                    <div className="desc text-justify">
                                        <p>We are a software development company that provides cutting edge engineering solutions,combining deep industry expertise and technical skills to connect you to the right IT solutions.</p>
                                        <p>Km Inc aims to provide unique and carefully crafted marketing plans with the implementation of tech and metrics to take your business to the next level. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section gmb-next-level web-design-together">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Serving You Better Everyday</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row about about-values no-gutters">
                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <div class="section-top text-left">
                                    <h3 class="light-black-txt fw700 mb-4">Our Values</h3>
                                    <div class="content-item">
                                        <ul>
                                            <li>Respect</li>
                                            <li>Honesty</li>
                                            <li>Integrity</li>
                                            <li>Transperancy</li>
                                            <li>TeamWork</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div class="section-top text-left">
                                    <h3 class="light-black-txt fw700 mb-4">Our Way</h3>
                                    <div class="content-item">
                                        <ul>
                                            <li>We put people first.</li>
                                            <li>We are honoured to work with you.</li>
                                            <li>We learn from our mistakes.</li>
                                            <li>We are accountable</li>
                                            <li>We are in the game.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                <div class="image">
                                    <img src={about2} class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Testimonials /> */}
            </>
        );
    }
}

export default About;
import React, { Component } from "react";


import BannerTitle from "../../components/BannerTitle";

import contact1 from '../../assets/images/servicesdata/contact1.svg';
import contact2 from '../../assets/images/servicesdata/contact2.svg';
import contact3 from '../../assets/images/servicesdata/contact3.svg';
import contact4 from '../../assets/images/servicesdata/contact4.svg';

import { Fade } from "react-awesome-reveal";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <BannerTitle title="Contact" />

                <section class="section contact about">
                    <div class="gmb-item-main">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <div class="section-top text-left ">
                                        <h3 class="black-txt fw700 mb-4">Get in Touch</h3>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="col-4 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <img class="img-fluid" src={contact1} />
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                        <p>G09-31 Gibson St,<br />
                                                Parry Sound,<br />
                                                ON P2A0A5
                                                </p>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="col-4 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <img class="img-fluid" src={contact2} />
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                            <p>Call us on:
                                                <a href="tel:+91-7990463627">+91-7990463627</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="col-4 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <img class="img-fluid" src={contact3} />
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                            <p>Whatsapp us:
                                                <a href="https://wa.me/message/3IAU7PM6NHR5C1" target="_blank">+91-7990463627</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="col-4 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <img class="img-fluid" src={contact4} />
                                        </div>
                                        <div class="col-4 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                            <p>Email us on:
                                                <a href="mailto:kmincorporation.online@gmail.com">kmincorporation.online@gmail.com</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div class="map-responsive">
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2804.309657110784!2d-80.03478129999999!3d45.342561100000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d2bba9303c5ef57%3A0x8bf0254935407fc2!2s31%20Gibson%20St%20G09%2C%20Parry%20Sound%2C%20ON%20P2A%200A5%2C%20Canada!5e0!3m2!1sen!2sin!4v1690293888172!5m2!1sen!2sin" width="600" height="450"  style={{ border: "0" }} loading="lazy"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section home-about gmb-next-level web-design-together">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="section-top text-center">
                                    <h3 class="black-txt fw700 mb-4">Our Office</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row">                            
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Fade  triggerOnce="true">
                                    <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Canada 🇨🇦</h3>
                                            <div class="map-responsive">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2804.309657110784!2d-80.03478129999999!3d45.342561100000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d2bba9303c5ef57%3A0x8bf0254935407fc2!2s31%20Gibson%20St%20G09%2C%20Parry%20Sound%2C%20ON%20P2A%200A5%2C%20Canada!5e0!3m2!1sen!2sin!4v1690293888172!5m2!1sen!2sin" width="600" height="450"  style={{ border: "0" }} loading="lazy"></iframe>
                                            </div>
                                            <div class="desc py-4 text-justify">
                                                <p>G09-31 Gibson St,<br />
                                                Parry Sound,<br />
                                                ON P2A0A5
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Fade  triggerOnce="true">
                                <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">Netherland 🇳🇱</h3>
                                            <div class="map-responsive">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.6720047236035!2d4.477373317443847!3d51.939936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5cb5590db8b4d%3A0x681920d4d2b87ffd!2sBergweg%2058%2C%203036%20BC%20Rotterdam%2C%20Netherlands!5e0!3m2!1sen!2sin!4v1662299728980!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} loading="lazy"></iframe>
                                            </div>
                                            <div class="desc py-4 text-justify">
                                                <p>Bergweg 58,<br/>
                                                3036 BC Rotterdam,<br/>
                                                Netherlands
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Fade  triggerOnce="true">
                                <div class="content-item">
                                        <div class="content-item-inner">
                                            <h3 class="light-black-txt fw700">India 🇮🇳</h3>
                                            <div class="map-responsive">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.8677300180084!2d70.74310181457362!3d22.282999749154545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cbc36c5aaadf%3A0xec3296a69a6c245b!2sPrashil%20Park!5e0!3m2!1sen!2sin!4v1662299806231!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} loading="lazy"></iframe>                                                
                                            </div>
                                            <div class="desc py-4 text-justify">
                                                <p>Prashil Park,<br/>
                                                Near Neel City Club,<br/>
                                                Rajkot - 360005                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Contact;
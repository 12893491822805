import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import LOGO from '../assets/images/logo.png'

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentYear : new Date().getFullYear(),
        }
    }

    render() {
        const {currentYear} = this.state;
        return (
            <>
                <footer id="footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div class="foot-item foot-1">
                                    <div class="foot-logo mb-2">
                                        <img src={LOGO} class="img-fluid" width={150} alt="Footer Logo" />
                                    </div>
                                    <div class="foot-content">
                                        <div class="add-item mb-2 white-txt">
                                            <div class="val">
                                                <p>
                                                    We are a software development company that provides cutting edge engineering solutions,combining deep industry expertise and technical skills to connect you to the right IT solutions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <div class="foot-item foot-2">
                                            <div class="foot-title white-txt text-uppercase fw700 lett2">Quick Links</div>
                                            <div class="foot-content">
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/about">About Us</NavLink>
                                                </div>
                                                {/* <div class="add-item white-txt d-flex">
                                                    <a href="#">Portfolio</a>
                                                </div> */}
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/contact">Contact</NavLink>
                                                </div>
                                                {/* <div class="add-item white-txt d-flex">
                                                    <a href="privacy-policy.html">Privacy Policy</a>
                                                </div>
                                                <div class="add-item white-txt d-flex">
                                                    <a href="ada_terms.html">Terms of Services</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <div class="foot-item">
                                            <div class="foot-title white-txt text-uppercase fw700 lett2">Services</div>
                                            <div class="foot-content">
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/web">Web Development</NavLink>
                                                </div>
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/automation">RPA & ChatBot</NavLink>
                                                </div>
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/data-processing">Data Processing</NavLink>
                                                </div>
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/digital-marketing">Digital Marketing</NavLink>
                                                </div>
                                                <div class="add-item white-txt d-flex">
                                                    <NavLink to="/app">App Development</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <div class="foot-item foot-3">
                                    <div class="foot-title white-txt text-uppercase fw700 lett2">Connect</div>
                                    <div class="foot-content">
                                        <div class="add-item mb-3 white-txt">
                                            <div class="icn"><i class="fa fa-solid fa-envelope" aria-hidden="true"></i></div>
                                            <div class="val">
                                                <a href="mailto: kmincorporation.online@gmail.com" title="Mail">kmincorporation.online@gmail.com</a>
                                            </div>
                                        </div>
                                        <div class="add-item white-txt">
                                            <div class="icn"><i class="fa fa-brands fa-whatsapp" aria-hidden="true"></i></div>
                                            <div class="val">
                                                <a href="https://wa.me/message/3IAU7PM6NHR5C1" target="_blank">+91-7990463627</a>
                                            </div>
                                        </div>


                                        <div class="add-item white-txt">
                                            Follow Us
                                        </div>
                                        <ul class="foot-social mt-4">
                                            <li><a href="https://www.facebook.com/kmincorporation2706" title="Facebook" target="_blank"><i class="fa fa-brands fa-facebook-f" aria-hidden="true"></i></a></li>
                                            <li><a href="https://wa.me/message/3IAU7PM6NHR5C1" title="Whatsapp" target="_blank"><i class="fa fa-brands fa-whatsapp" aria-hidden="true"></i></a></li>  
                                            <li><a href="https://twitter.com/kmincorp_online" title="Twitter" target="_blank"><i class="fa fa-brands fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href="https://www.instagram.com/kmincorporation_official/" title="Instagram" target="_blank"><i class="fa-brands fa-instagram" aria-hidden="true"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/k-m-incorporation" title="Linkedin" target="_blank"><i class="fa fa-brands fa-linkedin" aria-hidden="true"></i></a></li>
                                            <li><a href="https://discord.gg/yCc8jVPh" title="Discord" target="_blank"><i class="fa fa-brands fa-discord" aria-hidden="true"></i></a></li>
                                            <li><a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=403tjmuk" title="Line" target="_blank"><i class="fa fa-brands fa-line" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-strip">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="copy-right-text text-center white-txt">
                                        Copyright {currentYear} KM Inc | All Rights Reserved
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;